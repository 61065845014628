var ua = navigator.userAgent.toLowerCase();
var isiOS = (ua.indexOf('iphone') > -1) || (ua.indexOf('ipad') > -1);
if(isiOS) {
  var viewport = document.querySelector('meta[name="viewport"]');
  if(viewport) {
    var viewportContent = viewport.getAttribute('content');
    viewport.setAttribute('content', viewportContent + ', user-scalable=no');
  }
}

/**************************
 * リロード処理
 * ウインドウサイズが 750px を超えている状態から750px以下へと変化する度に、一度だけ実行。
 ********************************/
var timer = false;
var counter = false;  // リロードを実行するか判断するためのカウンタ (false で実行)
var prewidth = $(window).width();

// 読み込み時にウインドウが750pxを超えていれば初回はリロード実行しない
if(prewidth > 750){
  counter = true;
}

// リサイズ時に処理
$(window).resize(function() {
  var currentSize = $(window).width();  // リサイズ後のウインドウサイズ
  if((currentSize >= 750 )   // 条件1：ウインドウサイズが750px 以上
  && (counter == false)){    // 条件2：カウンタが false (i.e. 実行する)
    counter = true;
    if (timer !== false) {
        clearTimeout(timer);
    }
    timer = setTimeout(function() {
        var nowWidth = $(window).width();
        if(prewidth !== nowWidth){
    // リロード
            location.reload();
        }
        prewidth = nowWidth;
    }, 100);
  }else if(currentSize < 750){     // ウインドウサイズが 750pxを超える度に、一度だけカウンタをリセット
    counter = false;
  }
});

$(function(){
  //追従メニューの複製＋クラス付与
  if($(".h_wrap").length > 0) {
    $(".h_wrap").clone().addClass('h_fixed').appendTo("#header");
  }
  if($(".h_wrap").length > 0) {
    var headerHeight = $("#header").outerHeight();
    $(window).scroll(function() {
        if ($(this).scrollTop() > headerHeight) {
            $(".h_fixed").addClass("viewed");
        } else {
            $(".h_fixed").removeClass("viewed");
        }
    });
  }

  //ヘッダーホバー時にクラス付与
  if($("#top,#resno").length > 0) {
    $(window).on('load resize', function() {
      var ww = window.innerWidth;
      if (ww > 1110) {
        $(".h_wrap").hover(function() {
          $(this).addClass("hovered");
        },
        function() {
          $(this).removeClass("hovered");
        });
      } else {
        // SPのとき実行
      }
    });
  }

  //ヘッダーメニューホバー時のコンテンツ表示
  if($(".h_pc").length > 0) {
    $(".h_menu").each(function(){
      $(this).find(".menu_01,.menu_02,.menu_03,.menu_04,.menu_05").hover(function() {
        $(this).parents(".h_wrap").find(".h_slidedown_menu").addClass("open");
      },
      function() {
        $(this).parents(".h_wrap").find(".h_slidedown_menu").removeClass("open");
      });
    });
    $(".h_slidedown_sec").each(function(){
      $(this).hover(function() {
        $(this).parents(".h_slidedown_menu").addClass("open");
      },
      function() {
        $(this).parents(".h_slidedown_menu").removeClass("open");
      });
    });

    $(".menu_01, .menu_01_cont").hover(function() {
      $(".menu_01").addClass("open");
      $(".menu_01_cont").addClass("viewed");
    },
    function() {
      $(".menu_01").removeClass("open");
      $(".menu_01_cont").removeClass("viewed");
    });

    $(".menu_02, .menu_02_cont").hover(function() {
      $(".menu_02").addClass("open");
      $(".menu_02_cont").addClass("viewed");
    },
    function() {
      $(".menu_02").removeClass("open");
      $(".menu_02_cont").removeClass("viewed");
    });

    $(".menu_03, .menu_03_cont").hover(function() {
      $(".menu_03").addClass("open");
      $(".menu_03_cont").addClass("viewed");
    },
    function() {
      $(".menu_03").removeClass("open");
      $(".menu_03_cont").removeClass("viewed");
    });

    $(".menu_04, .menu_04_cont").hover(function() {
      $(".menu_04").addClass("open");
      $(".menu_04_cont").addClass("viewed");
    },
    function() {
      $(".menu_04").removeClass("open");
      $(".menu_04_cont").removeClass("viewed");
    });

    $(".menu_05, .menu_05_cont").hover(function() {
      $(".menu_05").addClass("open");
      $(".menu_05_cont").addClass("viewed");
    },
    function() {
      $(".menu_05").removeClass("open");
      $(".menu_05_cont").removeClass("viewed");
    });

    $(".menu_05, .menu_05_cont").hover(function() {
      $(".menu_05").addClass("open");
      $(".menu_05_cont").addClass("viewed");
    },
    function() {
      $(".menu_05").removeClass("open");
      $(".menu_05_cont").removeClass("viewed");
    });

    $(".menu_search").each(function(){
      $(this).hover(function() {
        $(this).parents(".h_wrap").find(".h_search_input").focus();
      },
      function() {
      });
    });
  }

  //会員系メニューの制御
  if($(".h_wrap").length > 0) {
    $(window).on('load resize', function() {
      var ww = window.innerWidth;
      if (ww > 1110) {
        if($(".h_wrap").hasClass("logged_in")){
            $(".h_member").each(function(){
              $(this).find(".popup_trigger").hover(function() {
                $(this).addClass("open");
                $(this).parents(".h_wrap").find(".h_member_popup").addClass("viewed");
              },
              function() {
                $(this).removeClass("open");
                $(this).parents(".h_wrap").find(".h_member_popup").removeClass("viewed");
              });
            });
            $(".h_member_popup").each(function(){
              $(this).hover(function() {
                $(this).addClass("viewed");
                $(this).parents(".h_wrap").find(".h_member_wrap .popup_trigger").addClass("open");
              },
              function() {
                $(this).removeClass("viewed");
                $(this).parents(".h_wrap").find(".h_member_popup .popup_trigger").removeClass("open");
              });
            });
        } else {
          $(".h_favorite").each(function(){
            $(this).find(".popup_trigger").hover(function() {
              $(this).addClass("open");
              $(this).parents(".h_wrap").find(".h_favorite_popup").addClass("viewed");
            },
            function() {
              $(this).removeClass("open");
              $(this).parents(".h_wrap").find(".h_favorite_popup").removeClass("viewed");
            });
          });
          $(".h_favorite_popup").each(function(){
            $(this).hover(function() {
              $(this).addClass("viewed");
              $(this).parents(".h_wrap").find(".h_favorite_wrap .popup_trigger").addClass("open");
            },
            function() {
              $(this).removeClass("viewed");
              $(this).parents(".h_wrap").find(".h_favorite_wrap .popup_trigger").removeClass("open");
            });
          });
        }
      } else {
        $(".h_member").each(function(){
          $(this).find(".popup_trigger").click(function(){
            $(".popup_trigger").removeClass("open");
            $(".h_search").removeClass("open");
            $(".h_menu_popup").removeClass("viewed");
            $(".h_slidedown_menu").removeClass("open");
            $(".menu_search").removeClass("viewed");
            $(".h_wrap").removeClass("hovered");

            $(this).toggleClass("open");
            $(this).parents(".h_wrap").find(".h_member_popup").toggleClass("viewed");
            $(".h_wrap").toggleClass("hovered");
          });
        });
        $(".h_favorite").each(function(){
          $(this).find(".popup_trigger").click(function(){
            $(".popup_trigger").removeClass("open");
            $(".h_search").removeClass("open");
            $(".h_menu_popup").removeClass("viewed");
            $(".h_slidedown_menu").removeClass("open");
            $(".menu_search").removeClass("viewed");
            $(".h_wrap").removeClass("hovered");

            $(this).toggleClass("open");
            $(this).parents(".h_wrap").find(".h_favorite_popup").toggleClass("viewed");
            $(".h_wrap").toggleClass("hovered");
          });
        });
        $(".h_search").each(function(){
          $(this).click(function(){
            $(".popup_trigger").removeClass("open");
            $(".h_search").removeClass("open");
            $(".h_menu_popup").removeClass("viewed");
            $(".h_slidedown_menu").removeClass("open");
            $(".menu_search").removeClass("viewed");
            $(".h_wrap").removeClass("hovered");

            $(this).toggleClass("open");
            $(this).parents(".h_wrap").find(".h_slidedown_menu").toggleClass("open");
            $(this).parents(".h_wrap").find(".menu_search").toggleClass("viewed");
            $(".h_wrap").toggleClass("hovered");
          });
        });

        $(".h_menu_popup_btn_close").click(function(){
          $(".popup_trigger").removeClass("open");
          $(".h_search").removeClass("open");
          $(".h_menu_popup").removeClass("viewed");
          $(".h_slidedown_menu").removeClass("open");
          $(".menu_search").removeClass("viewed");
          $(".h_wrap").removeClass("hovered");
        });
      }
    });
  }

  //ハンバーガーメニュー制御
  if($(".h_wrap").length > 0) {
    $(".menu_trigger").each(function(){
      $(this).click(function(){
        $(this).parents(".h_wrap").find(".h_slideright_menu").toggleClass("viewed");
        $("html").toggleClass("content_fixed");
      });
    });
    $(".h_slideright_menu_bg, .slideright_menu_btn_close, .btn_close").click(function(){
      $(".h_slideright_menu").removeClass("viewed");
      $("html").removeClass("content_fixed");
    });
  }
  if($(".h_slideright_menu").length > 0) {
    $(".toggle_link > a").each(function(){
      $(this).click(function(){
        $(this).toggleClass("open");
        $(this).next(".toggle_blk").slideToggle(200);
      });
    });
  }

  //TOP：メインスライダー
  if($(".mv_slider").length > 0) {
    $('.mv_slider .slider_blk').slick({
        autoplay:true,
        autoplaySpeed:5000,
        dots:true,
        pauseOnHover:false,
        pauseOnFocus:false,
        prevArrow: '<span class="slide-arrow prev-arrow"></span>',
        nextArrow: '<span class="slide-arrow next-arrow"></span>',
    });
  }

  //帯バナースライダー
  if($(".h_line_info").length > 0) {
    $('.h_line_info .h_line_info_inner').slick({
        autoplay:true,
        autoplaySpeed:5000,
        dots:false,
        arrows: false,
    });
  }
  if($(".h_line_bnr").length > 0) {
    $('.h_line_bnr .h_line_bnr_inner').slick({
        autoplay:true,
        autoplaySpeed:5000,
        dots:false,
        arrows: false,
    });
  }

  //注目の製品スライダー
  if($(".attention_items").length > 0) {
    $('.attention_items_slider').slick({
      autoplay:false,
      slidesToShow: 5,
      slidesToScroll: 5,
      prevArrow: '<span class="slide-arrow prev-arrow"></span>',
      nextArrow: '<span class="slide-arrow next-arrow"></span>',
      responsive: [{
          breakpoint: 1220,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
      },{
        breakpoint: 750,
        settings: 'unslick'
      }]
    });
  }

  //ランキングスライダー
  if($(".ranking_items").length > 0) {
    $('.ranking_items_slider').slick({
        autoplay:false,
        slidesToShow: 5,
        slidesToScroll: 5,
        prevArrow: '<span class="slide-arrow prev-arrow"></span>',
        nextArrow: '<span class="slide-arrow next-arrow"></span>',
        responsive: [{
            breakpoint: 1220,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
        },{
            breakpoint: 750,
            settings: 'unslick'
        }]
    });
  }

  //ページトップ
  if($(".btn_page_top").length > 0) {
    var showFlag = false;
    var topBtn = $('.btn_page_top');    
    var showFlag = false;
    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
          if (showFlag == false) {
              showFlag = true;
              topBtn.addClass("viewed");
          }
      } else {
          if (showFlag) {
              showFlag = false;
              topBtn.removeClass("viewed");
          }
      }
    });
    topBtn.click(function () {
      $('body,html').animate({
          scrollTop: 0
      }, 500);
      return false;
    });
  }

  //この商品を見ている人にオススメスライダー
  if($(".recommend_items").length > 0) {
    $('.recommend_items_slider').slick({
      autoplay:false,
      slidesToShow: 5,
      slidesToScroll: 5,
      prevArrow: '<span class="slide-arrow prev-arrow"></span>',
      nextArrow: '<span class="slide-arrow next-arrow"></span>',
      responsive: [{
          breakpoint: 1220,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
      },{
          breakpoint: 750,
          settings: 'unslick'
      }]
    });
  }

  //マイページ・クレジットカード削除ボタン
  $('.mdl').click(function () {
    $('.mdl_window').toggleClass('active');
    $('.mdl_inner').toggleClass('active');
  });

  //カートトップ(熨斗・ラッピング)
  $('.wrapping').each(function(){
    $(this).click(function () {
      $(this).addClass('target');
      var idx = $(this).attr('id');
      $('#mdl_window_' + idx).addClass('active');
      $('#mdl_wrapping_' + idx).addClass('active');
    });
  });
  $('.sword').each(function(){
    $(this).click(function () {
      $(this).addClass('target');
      var idx = $(this).attr('id');
      $('#mdl_window_' + idx).addClass('active');
      $('#mdl_sword_' + idx).addClass('active');
    });
  });


  $('.mdl_close').click(function () {
    $('.bottom_box').each(function(){
      $(this).removeClass('target');
    });
    $('.mdl_window').removeClass('active');
    $('.mdl_wrapping').removeClass('active');
    $('.mdl_sword').removeClass('active');
  });

  //カートトップ(熨斗・ラッピング)選択処理
  $('.btn_area button').each(function(){
    $(this).click(function(){
      $(this).parents('.mdl_wrapping').prev('.mdl_window').removeClass('active');
      $(this).parents('.mdl_wrapping').removeClass('active');
      $(this).parents('.mdl_sword').prev('.mdl_window').removeClass('active');
      $(this).parents('.mdl_sword').removeClass('active');
      if ($(this).parents(".cart_blk").find('.target').hasClass('wrapping')) {
        var slct_val1 = $(this).parents(".mdl_wrapping").find('#wrap_color option:selected').val();
        var slct_val2 = $(this).parents(".mdl_wrapping").find('#mess_pattern option:selected').val();
        $(this).parents(".cart_blk").find('.target .wrapping_data').text(slct_val1 + ' / ' + slct_val2);
      } else {
        var slct_val1 = $(this).parents(".mdl_sword").find('#sword_pattern option:selected').val();
        $(this).parents(".cart_blk").find('.target .sword_data').text(slct_val1);
      }
      if (slct_val1 == 'ラッピングを希望しない' || slct_val1 == '熨斗を希望しない') {
        $(this).parents(".cart_blk").find('.target').removeClass('check');
      } else {
        $(this).parents(".cart_blk").find('.target').addClass('check');
      }
      $('.bottom_box').each(function(){
        $(this).removeClass('target');
      });
    });
  });
});

//マイページ横スクロールメニュー
$(window).on('load resize', function() {
  var ww = window.innerWidth;
  if (ww > 1220) {

  } else {
    window.TBRS = {};
    $(document).ready(function() {
      var TBRS = window.TBRS || {};
      var gNav = $('.side_navi')
      if (gNav.length <= 0) {
        return false;
      }
      var div = $('<div class="gNavBtns"></div>')
      var prevBtn = $('<div class="gNavPrev nav_btn"></div>')
      var nextBtn = $('<div class="gNavNext nav_btn"></div>')
      div.append(prevBtn);
      div.append(nextBtn);
      gNav.parent().prepend(div);
      TBRS.gNav = new Sly(gNav, {
        horizontal: 1,
        itemNav: 'basic',
        smart: 1,
        activateOn: 'null',
        mouseDragging: 1,
        touchDragging: 1,
        releaseSwing: 1,
        speed: 300,
        elasticBounds: 1,
      });
      
      var index = null
      nextBtn.on('click', function() {
        if (index === null) {
        }
        if (gNav.find('li').length - 1 < index) {
          return false;
        }
        index++;
        TBRS.gNav.toStart(index);
      })
      prevBtn.on('click', function() {
        if (index === null) {
        }
        if (index === 0) {
          return false;
        }
        index--;
        TBRS.gNav.toStart(index);
      })
      TBRS.gNav.init();
      $(window).on('resize', function() {
        TBRS.gNav.reload();
        TBRS.gNav.toStart(index);
        $('.side_navi ul').css('width', $('.side_navi ul').width() + gNav.find('li').length);
      })
      $('.side_navi ul').css('width', $('.side_navi ul').width() + gNav.find('li').length);
    });
  }
});